import BaseService from "./BaseService";

class SaleProductService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }

  saleProductRelationInCategory(data) {
    return this.performRequest(BaseService.METHOD_GET, `get-by-event-product-standard`, data);
  }

  saleProductByCategoryId(id, data) {
    return this.performRequest(BaseService.METHOD_GET, `get-by-category/${id}`, data);
  }

  saleProductSubCommodity(product_standard_id, data) {
    return this.performRequest(BaseService.METHOD_GET, `get-by-product-standard/${product_standard_id}/sub-commodities`, data)
  }

}

export default new SaleProductService('sale-products');